import React, { useState, useEffect } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';
import styled, { keyframes } from 'styled-components';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import contact1 from './../assets/contact1.png';
import emailjs from 'emailjs-com';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5%;
  font-family: Arial, sans-serif;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const BannerContainer = styled.div`
  display: flex;
  background-color: #4CAF50;
  margin-top: 3%;
  color: white;
  padding: 40px;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-size: 4em;
  margin-bottom: 15px;
  font-weight: bold;
  animation: ${fadeIn} 0.5s ease-in-out;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-top:10%;
  }
`;

const Button = styled.button`
  background-color: white;
  color: #4CAF50;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: right;
  overflow: hidden;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Image = styled(animated.img)`
  max-width: 100%;
  height: auto;
  margin-bottom: -100px;
  border-radius: 25px;
  margin-top: 2%;

  
  @media (max-width: 1024px) {
    width: 60%;
    height: auto;
  margin-bottom: -180px;
  border-radius: 25px;
  margin-top: 2%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  margin-bottom: -100px;
  border-radius: 25px;
  margin-top: 2%;
  }
`;

const InfoBox = styled.div`
  margin-bottom: 20px;
  border-radius: 5px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const JobInfo = styled.div`
  margin-bottom: 15px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Select = styled.select`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const CheckboxGroup = styled.div`
  margin-bottom: 10px;
`;

const Checkbox = styled.input`
  margin-right: 5px;
  margin-top:1%;
`;

const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
`;

const SubmitButton = styled.button`
  background-color: #4a90e2;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width:10rem;
  
  &:hover {
    background-color: #357ae8;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const ToggleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:10%;
  }
`;

const Titlee = styled.div`
  font-size: 3em;
  margin-bottom: 1%;
  text-align: center;
`;

const ToggleButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 10px;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    margin: 5px 0;
    width: 10rem;
  }
`;

const AnimatedForm = animated(Form);
const AnimatedTitle = animated(Title);
const AnimatedButton = animated(Button);
const AnimatedInput = animated(Input);
const AnimatedSelect = animated(Select);
const AnimatedCheckboxGroup = animated(CheckboxGroup);
const AnimatedTextArea = animated(TextArea);
const AnimatedSubmitButton = animated(SubmitButton);

const CurrentOpeningPage = () => {
  const [currentForm, setCurrentForm] = useState('currentOpening');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    careerLevel: '',
    qualification: [],
    keySkills: '',
    experience: '',
    employmentType: '',
    aboutYourself: ''
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        qualification: checked
          ? [...prevState.qualification, value]
          : prevState.qualification.filter(item => item !== value)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email
    emailjs.send('service_nyn6f6d', 'template_ecp703w', {
      name: formData.name,
      email: formData.email,
      contactNumber: formData.contactNumber,
      careerLevel: formData.careerLevel,
      qualification: formData.qualification.join(', '),
      keySkills: formData.keySkills,
      experience: formData.experience,
      employmentType: formData.employmentType,
      aboutYourself: formData.aboutYourself
    }, 'ljGWQg4ELzx-Qhfhd')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setShowPopup(true);
        setFormData({
          name: '',
          email: '',
          contactNumber: '',
          careerLevel: '',
          qualification: [],
          keySkills: '',
          experience: '',
          employmentType: '',
          aboutYourself: ''
        });
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      }, (err) => {
        console.log('FAILED...', err);
      });
  };

  const formTransition = useTransition(currentForm, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-20px)' },
    config: { duration: 1000 }
  });

  const imageAnimation = useSpring({
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0%)' },
    config: { duration: 500 },
  });

  const renderFormContent = () => (
    <AnimatedForm onSubmit={handleSubmit}>
      <AnimatedInput type="text" name="name" placeholder="Name *" required onChange={handleChange} value={formData.name} />
      <AnimatedInput type="email" name="email" placeholder="Email *" required onChange={handleChange} value={formData.email} />
      <AnimatedInput type="text" name="contactNumber" placeholder="Contact Number *" required onChange={handleChange} value={formData.contactNumber} />
      <AnimatedSelect name="careerLevel" required onChange={handleChange} value={formData.careerLevel}>
        <option value="">Career Level *</option>
        <option value="entryLevel">Entry Level</option>
        <option value="midLevel">Mid Level</option>
        <option value="seniorLevel">Senior Level</option>
      </AnimatedSelect>
      <AnimatedCheckboxGroup>
        <Label>Qualification</Label>
        <div>
          <Checkbox type="checkbox" name="qualification" value="B.Tech/B.E" onChange={handleChange} checked={formData.qualification.includes("B.Tech/B.E")} /> B.Tech/B.E
        </div>
        <div>
          <Checkbox type="checkbox" name="qualification" value="M.Tech/M.E" onChange={handleChange} checked={formData.qualification.includes("M.Tech/M.E")} /> M.Tech/M.E
        </div>
        <div>
          <Checkbox type="checkbox" name="qualification" value="Other" onChange={handleChange} checked={formData.qualification.includes("Other")} /> Other
        </div>
      </AnimatedCheckboxGroup>
      <AnimatedInput type="text" name="keySkills" placeholder="Key Skills" onChange={handleChange} value={formData.keySkills} />
      <AnimatedInput type="text" name="experience" placeholder="Experience" onChange={handleChange} value={formData.experience} />
      <AnimatedSelect name="employmentType" required onChange={handleChange} value={formData.employmentType}>
        <option value="">Employment Type *</option>
        <option value="fullTime">Full Time</option>
        <option value="partTime">Part Time</option>
      </AnimatedSelect>
      <AnimatedTextArea name="aboutYourself" placeholder="About yourself *" required onChange={handleChange} value={formData.aboutYourself} />
      <AnimatedSubmitButton type="submit">Submit</AnimatedSubmitButton>
    </AnimatedForm>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <BannerContainer>
        <TextContainer>
          <AnimatedTitle>Careers</AnimatedTitle>
          {/*<p>Engineering Team of 18 Engineers with Mechanical, Electrical , IOT , Production background over five years of experience in the field of energy audit, also ARS has conducted over 450 training sessions in energy conservation, safety, ISO 50001, energy audit, and demand-side management.</p>*/}
        </TextContainer>
        <ImageContainer>
      
        </ImageContainer>
        <Image 
        style={imageAnimation}
        src={contact1} 
        alt="Solar Panels" 
      />
      </BannerContainer>
      <PageContainer>
        <ToggleButtonContainer>
          <ToggleButton onClick={() => setCurrentForm('currentOpening')}>Current Opening</ToggleButton>
          <ToggleButton onClick={() => setCurrentForm('hiringNow')}>Hiring Now</ToggleButton>
        </ToggleButtonContainer>
        <FormContainer>
          {formTransition((style, item) => item === 'currentOpening' ? (
            <animated.div style={style}>
              <Titlee>Current Opening</Titlee>
              <InfoBox>
                Career opportunities for Certified Energy Auditors/Managers, safety professionals, green building professionals, and
                Process Experts (Part time and project basis).
                Internship opportunities for individuals passionate about Energy Efficiency, Power Quality, Data Analytics, Digital
                Marketing, Web Designing & Content Writing.
              </InfoBox>
              <JobInfo>
                <p><Label>Designation:</Label> Business Development/ Technical Sales</p>
                <p><Label>Employment type:</Label> Full Time/Part time</p>
                <p><Label>Experience:</Label> 0-3 years ( preference for experience in services sales/ consulting sales)</p>
                <p><Label>Qualification:</Label> Bachelor's Degree or higher</p>
              </JobInfo>
              <JobInfo>
                <Label>Responsibilities:</Label>
                <ul>
                  <li>Enquiry generation from new customers / assigned segment</li>
                  <li>Service customers by identifying their needs and services</li>
                  <li>Identify current and future customer service requirements by establishing personal rapport with potential and actual customers and others in a position to understand service requirements</li>
                  <li>Develop and maintain a sales plan that focuses on key accounts and sales formula activities including planned site visits, product shows and mailings, tele-sales etc.</li>
                </ul>
                <p><Label>Note:</Label> We do not believe in resrvation system based on caste. Zero tolerance policy for caste hate.</p>
              </JobInfo>
              {renderFormContent()}
            </animated.div>
          ) : (
            <animated.div style={style}>
              <Titlee>Hiring Now</Titlee>
              <InfoBox>
                Career opportunities for Certified Energy Auditors/Managers, safety professionals, green building professionals, and
                Process Experts (Part time and project basis).
                Internship opportunities for individuals passionate about Energy Efficiency, Power Quality, Data Analytics, Digital
                Marketing, Web Designing & Content Writing.
              </InfoBox>
              <JobInfo>
                <p><Label>Position:</Label> Jr Energy Engineer & Energy analyst</p>
                <p><Label>Internship:</Label> 3 months </p>
                <p><Label>Office Location:</Label> Virar west </p>
                <p><Label>Salary:</Label> Not disclosed</p>
                <p> Candidates from Vasai ,Virar,Mumbai will be preferred .</p>
              </JobInfo>
              <JobInfo>
                <p><Label>Job Description:</Label>
                Electrical Utility measure (Power quality analysis, transformer primary &
                  secondary side electrical logging, Losses Finding, efficiency calculation, Harmonics, study,
                  SLD's preparation of electrical distribution from LT to DB, Detailed study of transformer
                  installation with respect to energy optimization). Thermal Utilities measurement like (Boiler
                  Flue gas analysis, Boiler surface thermography, Compressor power measurement & CFM
                  measurement of compressor, FAD (free air delivery) test, Thermic Fluid Heater flue gas
                  analysis, PHE (Plate heat exchanger) test, Cooling tower performance analysis, Pump
                  Performance analysis, Motor loading percentage calculation, AHU (air handling unit)
                  Measurement, Chiller Performance analysis etc.... You'll conduct audit for various plant
                  such as MEA(mandatory energy audit), Water audit, M&V pat cycle audit, Detailed energy
                  audit & electrical safety audit.</p>
                  <p><Label>Note:</Label> We do not believe in resrvation system based on caste. Zero tolerance policy for caste hate.</p>
              </JobInfo>
              {renderFormContent()}
            </animated.div>
          ))}
        </FormContainer>
      </PageContainer>
      <Footer />
      {showPopup && (
        <PopupOverlay>
          <PopupContent>
            <h3>Your form has been submitted. Thank you!</h3>
          </PopupContent>
        </PopupOverlay>
      )}
    </>
  );
};

export default CurrentOpeningPage;
