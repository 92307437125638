import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';
import ARS2  from './../assets/ARS2.png'
const colors = {
  background: '#e5f5e0',
  primaryText: '#004d00',
  secondary: '#009900',
  menuBackground: '#f1f8f1',
  hrColor: '#4CAF50',
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const Logo = styled.img`
  width: 100px;
  margin-bottom: 10px;
  height:2rem;
`;

const slideIn = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
`;

const expandLine = keyframes`
  0% { width: 0; opacity: 0; }
  50% { width: 100%; opacity: 0.5; }
  100% { width: 80%; opacity: 1; }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${colors.background};
  color: ${colors.primaryText};
  position: fixed;
  width: 100%;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;


const DesktopMenu = styled.div`
  display: flex;
  gap: 20px;
  margin-right: 2%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: ${colors.menuBackground};
  padding-top: 60px;
  transform: translateX(${({ open }) => (open ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  overflow-y: auto;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  animation-delay: ${props => props.delay}s;
`;

const MenuItem = styled(Link)`
  color: ${colors.primaryText};
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  padding: 20px 20px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;

  &:hover {
    background-color: ${colors.secondary};
    color: ${colors.background};
  }
`;

const MenuItemHr = styled.hr`
  width: 0;
  height: 2px;
  background-color: ${colors.hrColor};
  border: none;
  margin: 0;
  align-self: center;
  animation: ${expandLine} 0.5s ease-out forwards;
  animation-delay: ${props => props.delay}s;
`;

const HamburgerContainer = styled.div`
  display: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 25px;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }
`;

const HamburgerBar = styled.div`
  width: 25px;
  height: 3px;
  background-color: ${colors.primaryText};
  margin: 5px 0;
  transition: 0.4s;

  &:nth-child(1) {
    transform: ${({ open }) => open ? 'rotate(-45deg) translate(-5px, 6px)' : 'none'};
  }

  &:nth-child(2) {
    opacity: ${({ open }) => open ? '0' : '1'};
  }

  &:nth-child(3) {
    transform: ${({ open }) => open ? 'rotate(45deg) translate(-5px, -6px)' : 'none'};
  }
`;

const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 768px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
    opacity: ${({ open }) => (open ? '1' : '0')};
  }
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const menuItems = [
    { to: "/", label: "Home" },
    { to: "/service", label: "Service" },
    { to: "/faqs", label: "FAQs" },
    { to: "/enquiry", label: "Enquiry" },
    { to: "/portfolio", label: "Portfolio" },
    { to: "/career", label: "Career" },
    { to: "/Ourteam", label: "Team" },
  ];

  return (
    <>
      <NavbarContainer>
        <HamburgerContainer onClick={toggleMenu} aria-label={open ? "Close menu" : "Open menu"}>
          <HamburgerBar open={open} />
          <HamburgerBar open={open} />
          <HamburgerBar open={open} />
        </HamburgerContainer>
        <Logo src={ARS2} alt="ARS Logo" />
        <DesktopMenu>
          {menuItems.map((item, index) => (
            <MenuItem key={index} to={item.to} aria-label={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </DesktopMenu>
      </NavbarContainer>
      <MobileMenu open={open}>
        {menuItems.map((item, index) => (
          <MenuItemContainer key={index} delay={0.1 + index * 0.1}>
            <MenuItem to={item.to} aria-label={item.label} onClick={closeMenu}>
              {item.label}
            </MenuItem>
            <MenuItemHr delay={0.2 + index * 0.1} />
          </MenuItemContainer>
        ))}
      </MobileMenu>
      <Overlay open={open} onClick={closeMenu} />
    </>
  );
};

export default Navbar;