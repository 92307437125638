import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './../src/pages/Home';
import About from './../src/pages/About';
import FAQs from './pages/FAQs';
import Homecomponent from './pages/Homecomponent';
import Ourteam from './pages/Ourteam';
import ContactUs from './pages/ContactUs';
import Enquiry from './pages/Enquiry';
import Vision from './pages/Vision';
import Career from './pages/Career';



function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Service" element={<About />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/Portfolio" element={<ContactUs />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/homecomponent" element={<Homecomponent />} />
        <Route path="/vision" element={<Vision/>} />
        <Route path="/career" element={<Career/>} />
        <Route path="/ourteam" element={<Ourteam/>} />

    </Routes>
  );
}

export default App;
