import React, { useState } from 'react';
import styled from 'styled-components';
import ACC1 from './../assets/ACC1.jpg'
import MEDA from './../assets/MEDA.jpg'
import BEE from './../assets/BEE.jpg'

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  color: navy;
`;

const CustomerName = styled.span`
  color: #4CAF50;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  margin-top: 30px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;

  height: auto;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border:1px green solid;

  &:hover {
    transform: scale(1.05);
  }
`;

const Caption = styled.p`
  margin-top: 10px;
  font-style: italic;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
`;

const CustomerWords = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { src: BEE, caption: 'CERTIFICATION OF ACCREDITION ' },
    { src: ACC1, caption: 'CERTIFIED ENERGY MANAGER' },
    { src: MEDA, caption: 'FIRST PRIZE MEDA AWARD' },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <Container>
      <Title>Accreditation and <CustomerName>Achievements</CustomerName></Title>
      <ImageGrid>
        {images.map((image, index) => (
          <ImageContainer key={index}>
            <Image 
              src={image.src} 
              alt={`Certification ${index + 1}`} 
              onClick={() => openModal(image)}
            />
            <Caption>{image.caption}</Caption>
          </ImageContainer>
        ))}
      </ImageGrid>
      {selectedImage && (
        <Modal onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImage src={selectedImage.src} alt={selectedImage.caption} />
            <Caption>{selectedImage.caption}</Caption>
          </ModalContent>
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </Modal>
      )}
    </Container>
  );
};

export default CustomerWords;