import React, { useState ,useEffect } from 'react';
import { useSpring, animated, useTrail } from 'react-spring';
import styled, { keyframes } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import contact from './../assets/contact.avif';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import SIR from './../assets/SIR.jpeg';
import cement from './../assets/cement.jpg';
import aluminium from './../assets/aluminium.jpeg';
import chlor from './../assets/chlor.jpg';
import F from './../assets/F.jpeg';
import iron from './../assets/iron.jpg';
import PP from './../assets/PP.jpeg';
import TT from './../assets/TT.jpeg';
import TPP from './../assets/TPP.jpeg';
import DIS from './../assets/DIS.jpg';
import REF from './../assets/REF.jpeg';
import PET from './../assets/PET.jpeg';
import BUI from './../assets/BUI.jpeg';
import DAI from './../assets/DAI.jpeg';
import CER from './../assets/CER.jpg';
import GLA from './../assets/GLA.jpg';
import PHA from './../assets/PHA.jpeg';



const colors = {
  background: '#ffffff',
  primaryText: '#004d00',
  secondary: '#009900',
  menuBackground: '#f1f8f1',
  green: '#4CAF50',
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 3%;
  font-family: Arial, sans-serif;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Banner = styled.div`
  background-color: ${colors.green};
  color: white;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const BannerText = styled.div`
  flex: 1;
`;

const BannerTitle = styled.h1`
  font-size: 4em;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    margin-top:15%;
  }
`;

const BannerSubtitle = styled.p`
  font-size: 1em;
`;

const BannerImage = styled.img`
  max-width: 41%;
  z-index: 1;
  margin-top: 4%;
  height: auto;
  margin-bottom: -70px;
  border-radius:25px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin-top: 5%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: ${colors.primaryText};
  margin-bottom: 20px;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px;
`;

const DirectorSection = styled.section`
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  gap: 20px;
  border-radius:10px;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;

  @media (max-width: 430px) {
    display: flex;
    margin-top: 10%;
    flex-direction: column;
  }
`;

const ImagePlaceholder = styled.img`
  width: ${props => props.small ? '100px' : '150px'};
  height: ${props => props.small ? '100px' : '150px'};
  background-color: #ddd;
  flex-shrink: 0;
`;

const DirectorInfo = styled.div`
  h2 {
    color: #333;
    margin-top: 0;
  }
  p {
    margin-bottom: 10px;
  }
`;

const ConsultantsSection = styled.section`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;

  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

const ConsultantCard = styled(animated.div)`
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ConsultantInfo = styled.div`
  h3 {
    margin-top: 0;
    color: #333;
  }
`;

const ConsultantImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const AssociateConsultantsSection = styled.section`
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;

  h2 {
    color: #333;
  }
`;

const JoinTeamButton = styled.button`
  background-color: #8cc63f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #7ab32e;
  }
`;

const AnimatedBannerTitle = animated(BannerTitle);
const AnimatedBannerSubtitle = animated(BannerSubtitle);
const AnimatedBannerImage = animated(BannerImage);
const AnimatedDirectorSection = animated(DirectorSection);
const AnimatedConsultantsSection = animated(ConsultantsSection);
const AnimatedAssociateConsultantsSection = animated(AssociateConsultantsSection);
const AnimatedJoinTeamButton = animated(JoinTeamButton);

const TeamPage = () => {
  const titleAnimation = useSpring({
    from: { transform: 'translateY(-50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
  });

  const subtitleAnimation = useSpring({
    from: { transform: 'translateY(-30px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
    delay: 200,
  });

  const imageAnimation = useSpring({
    from: { transform: 'translateX(50px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
    delay: 300,
  });

  const trail = useTrail(6, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { mass: 5, tension: 2000, friction: 200 },
  });

  const associatedConsultants = [
    {
      name: "Consultant 1",
      image: aluminium,
      industrySector: "Aluminium",
      expertsCount: 1
    },
    {
      name: "Consultant 2",
      image: cement,
      industrySector: "Cement",
      expertsCount: 1
    },
    {
      name: "Consultant 3",
      image: chlor,
      industrySector: "Chlor Alkali",
      expertsCount: 1
    },
    {
      name: "Consultant 4",
      image: F,
      industrySector: "Fertilizers",
      expertsCount: 1
    },
    {
      name: "Consultant 5",
      image: iron,
      industrySector: "Iron & Steel",
      expertsCount: 1
    },
    {
      name: "Consultant 6",
      image: PP,
      industrySector: "Pulp & Paper",
      expertsCount: 1
    },
    {
      name: "Consultant 7",
      image: TT,
      industrySector: "Textiles",
      expertsCount: 2
    },
    {
      name: "Consultant 8",
      image: TPP,
      industrySector: "Thermal Power Plant",
      expertsCount: 1
    },
    {
      name: "Consultant 9",
      image: DIS,
      industrySector: "Discoms",
      expertsCount: 1
    },
    {
      name: "Consultant 10",
      image: REF,
      industrySector: "Refinery",
      expertsCount: 1
    },
    {
      name: "Consultant 11",
      image: PET,
      industrySector: "Petrochemical",
      expertsCount: 1
    },
    {
      name: "Consultant 12",
      image:BUI ,
      industrySector: "Buildings",
      expertsCount: 2
    },
    {
      name: "Consultant 13",
      image: DAI,
      industrySector: "Dairy",
      expertsCount: 1
    },
  
    {
      name: "Consultant 14",
      image: CER,
      industrySector: "Ceramic",
      expertsCount: 1
    },
    {
      name: "Consultant 15",
      image:GLA ,
      industrySector: "Glass",
      expertsCount: 1
    },
    {
      name: "Consultant 16",
      image: PHA,
      industrySector: "Pharma",
      expertsCount: 1
    },
  
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Banner>
        <BannerText>
          <AnimatedBannerTitle style={titleAnimation}>Our Team</AnimatedBannerTitle>
          <AnimatedBannerSubtitle style={subtitleAnimation}>
            Engineers Team of 18 Engineers with Mechanical, Electrical and Production <br></br>background over five years of experience in the field of energy audit.
          </AnimatedBannerSubtitle>
        </BannerText>
        <AnimatedBannerImage src={contact} alt="Solar Panels" style={imageAnimation} />
      </Banner>
      <PageContainer>
        <Content>
          <AnimatedDirectorSection style={trail[0]}>
            <ImagePlaceholder src={SIR} alt="Solar Panels" style={imageAnimation} />
            <DirectorInfo>
              <h2>Mr. Sachin S. Deshpande - Director</h2>
              <p>
                <strong>Qualifications:</strong> CEM(AEE,USA) Accredited Energy Auditor (0261,B.E.E.),M.Tech(Energy),Solar P V System Tech (I.I.T. Chennai),Lead Auditor-En-Ms 50001,F.I.E,F.I.V,Chartered Engineer ,ISO 14064 Lead Verifier/Validator.
              </p>
              <p>
                <strong>Experience:</strong> Experienced consultant with 33+ years in ESCO projects, energy audits, and sustainability. Led national and international teams, managed 500MW solar installations, and completed 1000+ audits. Expertise in energy efficiency, clean energy, and sustainability across industries such as power, steel, textiles, and more. Established and diversified companies providing comprehensive services in installation, audits, carbon footprinting, and clean energy solutions.
              </p>
            </DirectorInfo>
          </AnimatedDirectorSection>
          <AnimatedConsultantsSection style={trail[1]}>
            <ConsultantCard>

              <ConsultantInfo>
              
                <p><strong>Our engineering team consist of Certified Energy Auditors and 18 Engineers with background in Mechanical, Electrical, IOT and Production Engineering domain with over five years of experience in the field of energy audit ands also pool of 26 experienced sector experts for various fields</strong> </p>

              </ConsultantInfo>
            </ConsultantCard>
           
          </AnimatedConsultantsSection>
          <AnimatedAssociateConsultantsSection style={trail[2]}>
            <h2>Associate Consultants</h2>
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {associatedConsultants.map((consultant, index) => (
                <SwiperSlide key={index}>
                  <ConsultantCard>
                    <ConsultantImage src={consultant.image} alt={consultant.name} />
                    <h3>{consultant.name}</h3>
                    <p><strong>Industry Sector:</strong> {consultant.industrySector}</p>
                    <p><strong>Numbers of sector experts:</strong> {consultant.expertsCount}</p>
                  </ConsultantCard>
                </SwiperSlide>
              ))}
            </Swiper>
          </AnimatedAssociateConsultantsSection>
   
        </Content>
      </PageContainer>
      <Footer />
    </>
  );
};

export default TeamPage;