import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Styling for the layout
const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #e5f5e0;
  gap: 20px;

  /* Media query for mobile view */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Styling for the FAQ section
const FAQSection = styled.section`
  flex: 2;
  background-color: #e5f5e0;
  padding: 40px 20px;
  text-align: center;
`;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: auto;
`;

const FAQItem = styled.div`
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
`;

const FAQHeader = styled.div`
  padding: 15px;
  cursor: pointer;
  background: #4caf50; /* Green color */
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s ease;

  &:hover {
    background: #388e3c; /* Darker green on hover */
  }
`;

const FAQContent = styled.div`
  padding: 15px;
  max-height: ${props => (props.open ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease;
  text-align:start;
  background: #f1f1f1;
  box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.1);
`;

const Arrow = styled.span`
  font-size: 24px; /* Increased size */
  transition: transform 0.3s ease;
  transform: rotate(${props => (props.open ? '45deg' : '0')});
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  color: white; /* Icon color */
  font-family: Arial, sans-serif; /* Ensure proper font styling */
`;

// Styling for the review section
const ReviewSection = styled.section`
  flex: 1;
  padding: 40px 20px;
  // background-color: #f9f9f9;
`;

const ReviewCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #4caf50;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ReviewPhoto = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const ReviewContent = styled.div`
  flex: 1;
`;

const StarRating = styled.div`
  color: gold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: '1. What is an energy audit?',
      answer: 'An energy audit is a systematic inspection and analysis of energy use and consumption in a building or organization. The purpose is to identify opportunities to improve energy efficiency, reduce energy costs, and enhance overall performance.'
    },
    {
      question: '2. Why is an energy audit important?',
      answer: 'Energy audits help organizations understand their energy usage patterns, identify inefficiencies, and recommend measures to save energy and reduce costs. This can lead to significant financial savings and environmental benefits.'
    },
    {
      question: '3. What are the types of energy audits?',
      answer: 'Energy audits typically come in three levels: - 1. Walk-through Audit: A preliminary assessment to identify immediate opportunities for energy savings . 2. Detailed Energy Audit: A comprehensive analysis of energy use, including data collection and detailed calculations . 3. Investment-Grade Audit: An in-depth audit providing detailed project costs, savings calculations, and financial analysis for energy efficiency investments.'
    },
    {
      question: '4. What sectors can benefit from energy audits?',
      answer: 'Energy audits can benefit various sectors, including:- Industrial (e.g., manufacturing, power plants)- Commercial (e.g., office buildings, hotels, hospitals)- Residential- Agricultural- Public sector (e.g., municipal corporations, railways)'
    },
    {
      question: '5. What is the process of conducting an energy audit?',
      answer: 'The energy audit process generally involves: 1. Preliminary data collection and review. 2. On-site inspection and measurements. 3. Analysis of energy use patterns. 4. Identification of energy-saving opportunities. 5. Recommendations for energy conservation measures. 6. Reporting and implementation planning.'
    },
    {
      question: '6. What are GHG audits?',
      answer: 'GHG (Greenhouse Gas) audits involve the assessment of an organizations carbon footprint by measuring emissions across Scope 1 (direct emissions), Scope 2 (indirect emissions from purchased energy), and Scope 3 (other indirect emissions). The goal is to identify ways to reduce GHG emissions and comply with regulatory requirements.'
    },
    {
      question: '7. How can an energy audit help in achieving compliance with regulations?',
      answer: 'Energy audits can help organizations identify and implement energy conservation measures required by regulations such as the EC 2001 Act, BEE compliance, and other local and international standards. This ensures that the organization meets legal requirements and avoids potential penalties.'
    },
    {
      question: '8. What is the PAT scheme?',
      answer: 'The Perform, Achieve, Trade (PAT) scheme is an initiative by the Bureau of Energy Efficiency (BEE) under the National Mission for Enhanced Energy Efficiency. It aims to improve energy efficiency in energy-intensive industries by setting specific energy consumption targets. Organizations that exceed these targets can trade energy savings certificates.'
    },
    {
      question: '9. What are the benefits of conducting a GHG audit?',
      answer: 'GHG audits help organizations:- Identify and reduce carbon emissions.- Enhance sustainability reporting.- Comply with regulatory requirements.- Improve brand image and corporate responsibility.- Potentially earn revenue from carbon credits.'
    },
    {
      question: '10. How can A.R.S. ENERGY AUDITORS help with energy audits and GHG evaluations?',
      answer: 'A.R.S. ENERGY AUDITORS offers comprehensive energy audit services, including detailedenergy assessments, implementation of energy conservation measures, and GHG evaluations. Our team of experts uses the latest technology and methodologies to help organizations optimize their energy use, reduce costs, and achieve regulatory compliance.'
    },
    {
      question: '11. How much does an energy audit cost?',
      answer: 'The cost of an energy audit varies depending on the scope and complexity of the audit. A.R.S. ENERGY AUDITORS offers competitive pricing at very resonable cost for Mandatory Energy Audit , GHG evaluations , Water Audit , Monitoring and Verfication Audit , Electrical Safety Audit . Contact us for a detailed quote tailored to your specific needs.'
    },
    {
      question: '12. How do I get started with an energy audit?',
      answer: 'To get started with an energy audit, contact A.R.S. ENERGY AUDITORS. Our team will work with you to understand your needs, gather initial data, and schedule an on-site inspection. We will then provide a detailed report with recommendations for energy efficiency improvements.'
    }
  ];

  const reviews = [
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★★★',
      review: 'Excellent service! Highly recommend for anyone looking for an energy audit.'
    },
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★★☆',
      review: 'Good experience overall, but the process took a bit longer than expected.'
    },
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★★★',
      review: 'Very thorough audit and helpful recommendations. Worth the investment.'
    },
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★☆☆',
      review: 'The audit was okay, but I was hoping for more detailed insights.'
    },
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★★★',
      review: 'Fantastic service and great customer support. Would use again!'
    },
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★☆☆',
      review: 'The audit was okay, but I was hoping for more detailed insights.'
    },
    {
      photo: 'https://via.placeholder.com/60',
      rating: '★★★★★',
      review: 'Fantastic service and great customer support. Would use again!'
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Layout>
        <FAQSection>
          <h1 style={{color:"green",marginTop:"4%"}}>Frequently Asked Questions</h1>
          <FAQContainer>
            {faqs.map((faq, index) => (
              <FAQItem key={index}>
                <FAQHeader onClick={() => toggleFAQ(index)}>
                  {faq.question}
                  <Arrow open={openIndex === index}>
                    {openIndex === index ? '-' : '+'}
                  </Arrow>
                </FAQHeader>
                <FAQContent open={openIndex === index}>
                  <p style={{color:"green"}}>{faq.answer}</p>
                </FAQContent>
              </FAQItem>
            ))}
          </FAQContainer>
        </FAQSection>
        
      </Layout>
      <Footer />
    </>
  );
};

export default FAQs;
