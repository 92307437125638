import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSpring, animated, useTrail } from 'react-spring';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import service2 from './../assets/service2.jpg';
import h1 from './../assets/h1.png';
import h2 from './../assets/h2.png';
import h3 from './../assets/h3.png';
import h4 from './../assets/h4.png';
import h5 from './../assets/h5.png';
import h6 from './../assets/h6.png';
import h7 from './../assets/h7.png';
import h8 from './../assets/h8.png';
import h9 from './../assets/h9.png';
import h10 from './../assets/h10.png';
import h11 from './../assets/h11.png';
import h12 from './../assets/h12.png';
import Footer from '../components/Footer';

// Service data
const servicesData = [
  {
    title: 'Mandatory & Investment Grade Energy Audits - ASHRAE level 3 audits ',
    description: 'Comprehensive energy audits, including turnkey and performance-based solutions, to optimize energy efficiency with ample support in terms of effiecieny resources and energy auditing intruments',
    image: h1
  },
  {
    title: 'M&V Audits & Check Verification Audits (BEE, Govt. of India)',
    description: 'Mandatory monitoring and verification audits, ensuring compliance with BEE standards and Scrutiny of M&V Audits (check verification audits)',
    image: h2
  },
  {
    title: 'ECBC, Green Building, Carbon Footprinting Consultancy',
    description: 'Specialized consultancy for energy conservation building codes, green building certifications, and carbon footprint analysis.',
    image: h3
  },
  {
    title: 'IoT-Based Energy Monitoring System Design & Implementation',
    description: 'End-to-end design and implementation of IoT-based systems for real-time energy monitoring and management.',
    image: h4
  },
  {
    title: 'Electrical Safety Audits (IE 1956/ NBC 2016)',
    description: 'Comprehensive electrical safety audits as per the 1956 regulations and National Building Code standards.',
    image: h5
  },
  {
    title: 'Techno-Commercial Feasibility / Evaluation And Valutions Of Plant And Machinery / Life cycle Assesment',
    description: 'Detailed feasibility studies and evaluations of industrial systems, utilities,  processes , plant and machinery valuation .',
    image: h6
  },
  {
    title: 'Detailed/ Level 3 Water Audits',
    description: 'In-depth water audits to assess and optimize water usage and management practices and roadmap to reduce specific water consumption, risk assessment studies, Net Positive Water Impact Assesment consultancy',
    image: h7
  },
  {
    title: 'Energy Strategy Planning Load Research Activity Discomps, DSM Training And Implementations',
    description: 'District cooling consultancy, Solar PV DPR',
    image: h8
  },
  {
    title: 'PAT Scheme Consultancy (BEE Programme / CCTS)',
    description: 'Consultancy services for the Perform, Achieve, and Trade (PAT) scheme / CCTS under the Bureau of Energy Efficiency.',
    image: h9
  },
  {
    title: 'Safety Management Consulting & Risk Analysis',
    description: 'Safety consulting, risk analysis, and HAZOP analysis for comprehensive risk management.',
    image: h10
  },
  {
    title: 'BR & SR Reporting (SEBI Guidelines)',
    description: 'Business Responsibility and Sustainability Reporting (BR & SR) related to energy, water, and carbon, as per SEBI guidelines.',
    image: h11
  },
  {
    title: 'Energy Consultancy for Projects Under Development, Performance & ESCO Contract Agreements',
    description: 'Energy efficiency predictions and consultancy for projects in the development phase.',
    image: h12
  },
];

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const bannerAnimation = useSpring({
    from: { transform: 'translateY(-50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
  });

  const bannerImageAnimation = useSpring({
    from: { transform: 'translateX(50px) scale(1.1)', opacity: 0 },
    to: { transform: 'translateX(0) scale(1)', opacity: 1 },
    config: { tension: 300, friction: 15 },
  });

  const trail = useTrail(servicesData.length, {
    from: { opacity: 0, transform: 'translate3d(0, 40px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <GlobalStyle />
      <animated.div style={fadeIn}>
        <Banner>
          <BannerText>
            <AnimatedBannerTitle style={bannerAnimation}>Our Services</AnimatedBannerTitle>
            <p> Your partner in sustainable energy solutions</p>
          </BannerText>
          <animated.div style={bannerImageAnimation}>
            <BannerImage src={service2} alt="Solar Panels" />
          </animated.div>
        </Banner>
      </animated.div>
      <Container>
        <h2>Services</h2>
        <ServicesSection>
          {trail.map((props, index) => (
            <ServiceCard
              key={index}
              style={props}
              animate={hoveredCard === index ? { scale: 1.05 } : { scale: 1 }}
              whileHover={{ 
                scale: 1.05,
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
              }}
              whileTap={{ scale: 1.05 }}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
              onClick={() => setHoveredCard(index === hoveredCard ? null : index)}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <Avatar
                as={motion.img}
                src={servicesData[index].image}
                alt={servicesData[index].title}
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 300 }}
              />
              <ServiceInfo>
                <h3>{servicesData[index].title}</h3>
                <p>{servicesData[index].description}</p>
              </ServiceInfo>
            </ServiceCard>
          ))}
        </ServicesSection>
      </Container>
      <ContactSection>
        <h2>Got project? Let's Connect!</h2>
        <p>Maximize your savings with our precise energy audits and sustainable energy strategies</p>
        <ContactButton
          as={motion.a}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          href="enquiry"
        >
          Get in touch
        </ContactButton>
      </ContactSection>
      <Footer />
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f0f0f0;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 70%;

  @media (max-width: 1024px) {
    width: 86%;
    margin-top:0%;
  }

  h2 {
    font-size: 32px;
    text-align: center;
    margin-top:7%;

    @media (max-width: 375px) {
      margin-top:26% !important;
      margin-bottom:2% !important;
    }
          @media (max-width: 1024px) {
      margin-top:8%;
      margin-bottom:1%;
    }
              @media (max-width: 768px) {
      margin-top:10%;
      margin-bottom:1%;
    }
                  @media (max-width: 425px) {
      margin-top:18%;
      margin-bottom:1%;
    }
  }
  
  @media (max-width: 375px) {
    margin-top:10%;
  }
`;

const Banner = styled.div`
  display: flex;
  background-color: #4CAF50;
  color: white;
  padding: 40px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const BannerText = styled.div`
  flex: 1;
   @media (max-width: 768px) {
    margin-top: 10%;
  }
`;

const AnimatedBannerTitle = styled(animated.h1)`
  font-size: 4em;
  margin-bottom: 15px;
  font-weight: bold;
`;

const BannerImage = styled.img`
  max-width: 70%;
  height: auto;
  margin-left:28%;

  margin-bottom: -100px;
  border-radius: 25px;
  margin-top:8%;
  @media (max-width: 1024px) {
  
     margin-left:30%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
     margin-left:0%;
  }
`;

const ServicesSection = styled.section`
  text-align: center;
  padding: 40px 20px;
  margin-top: 10px;


    @media (max-width: 425px) {
   
       padding-top:2%
  }
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  @media (max-width: 375px) {
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    padding: 0px;
  }
`;

const ServiceCard = styled(motion.div)`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:focus, &:active {
    outline: none;
    background-color: white; /* Remove blue background color */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
    color: #666;
  }
`;

const Avatar = styled(motion.img)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const ServiceInfo = styled.div`
  h3 {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 0.9em;
    color: #666;
  }
`;

const ContactSection = styled.section`
  text-align: center;
  background-color: #4CAF50;
  color: white;
  padding: 50px 20px;

  h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
`;

const ContactButton = styled(motion.a)`
  display: inline-block;
  padding: 15px 30px;
  font-size: 1em;
  color: #4CAF50;
  background-color: white;
  border-radius: 25px;
  text-decoration: none;
  cursor: pointer;
`;

export default Services;
