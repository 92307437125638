import React from 'react';
import styled from 'styled-components';
import ARS2 from './../assets/ARS2.png';
import { FaPhone, FaEnvelope, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  const phoneNumber1 = '7276158223';
  const phoneNumber2 = '7507184478';
  const phoneLink1 = `tel:${phoneNumber1}`;
  const phoneLink2 = `tel:${phoneNumber2}`;
  const emailAddress = 'arskcal@gmail.com';
  const emailLink = `mailto:${emailAddress}`;

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <Logo src={ARS2} alt="ARS Logo" />
          <p>Your partner for sustainable future.</p>
        </FooterSection>
        <FooterSection>
          <FooterLink to="/">Home</FooterLink>
          <FooterLink to="/Service">Services</FooterLink>
          <FooterLink to="/portfolio">Portfolio</FooterLink>
        </FooterSection>
        <FooterSection>
          <h3>Contact Us</h3>
          <ContactItem>
            <ContactLink href={phoneLink1}>
              <FaPhone /> +91 {phoneNumber1}
            </ContactLink>
          </ContactItem>
          <ContactItem>
            <ContactLink href={phoneLink2}>
              <FaPhone /> +91 {phoneNumber2}
            </ContactLink>
          </ContactItem>
          <ContactItem>
            <ContactLink href={emailLink}>
              <FaEnvelope /> {emailAddress}
            </ContactLink>
          </ContactItem>
          <SocialIcons>
            <SocialLink href="https://www.linkedin.com/company/a-r-s-energy-auditors/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </SocialLink>
            {/* <FaFacebook /> */}
          </SocialIcons>
        </FooterSection>
      </FooterContent>
      <FooterBottom>
        <p>Copyrights © 2024. All Rights Reserved</p>
      </FooterBottom>
    </FooterContainer>
  );
};

const colors = {
  background: '#e5f5e0',
  primaryText: '#004d00',
  secondary: '#009900',
  menuBackground: '#f1f8f1',
  hrColor: '#4CAF50',
};

const FooterContainer = styled.footer`
  background-color: ${colors.background};
  color: black;
  padding: 20px 0;
  text-align: center;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: black;
  }

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  max-width: 150px;
  margin-bottom: 10px;
  height: 5rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  color: black;

  svg {
    margin-right: 10px;
  }
`;

const ContactLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  font-size: 25px;

  svg {
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      color: #4CAF50;
    }
  }
`;

const SocialLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #0e76a8;
  }
`;

const FooterBottom = styled.div`
  padding: 10px 0;
  margin-top: 20px;
  text-align: center;

  p {
    margin: 0;
    font-size: 12px;
    color: black;
  }
`;

export default Footer;