import React, { useState, useEffect } from 'react';
import { useSpring, animated, useTrail } from 'react-spring';
import styled, { keyframes } from 'styled-components';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import bulb from './../assets/bulb.jpeg';
import reliance from './../assets/reliance.png';
import BEE from './../assets/BEE.jpeg';
import SIE from './../assets/SIE.jpg';
import JSW from './../assets/JSW.jpg';
import TATA1 from './../assets/TATA1.jpeg';
import RBI from './../assets/RBI.png';
import GOVT from './../assets/GOVT.jpeg';
import TAJ from './../assets/TAJ.jpg';
import GOV5 from './../assets/GOV5.jpg';
import PWC from './../assets/PWC.png';
import GH from './../assets/GH.jpeg';
import SS from './../assets/SS.jpg';
import TUV from './../assets/TUV.jpeg';

const works = [
  { id: 1, image: reliance, description: 'RELIANCE' },
  { id: 2, image: BEE, description: 'BEE' },
  { id: 3, image: RBI, description: 'RBI' },
  { id: 4, image: JSW, description: 'JSW' },
  { id: 5, image: TATA1, description: 'TATA POWER' },
  { id: 6, image: SIE, description: 'SIEMENS' },
  
  { id: 7, image: TAJ, description: 'TAJ HOTELS' },
  { id: 8, image: GOV5, description: 'GOVERNMENT OF INDIA' },  
  { id: 9, image: PWC, description: 'PWC' },
  { id: 10, image: GH, description: 'GRAND HYATT' },
  { id: 11, image: SS, description: 'SANYO SPECIAL STEEL' },
  { id: 12, image: TUV, description: 'TUV SUD' },
];

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BannerContainer = styled.div`
  display: flex;
  background-color: #4CAF50;
  margin-top: 3%;
  color: white;
  padding: 40px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 13%;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-top: 15%;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: -100px;
  margin-top: 1%;
  border-radius: 25px;
  z-index: 1;
`;

const MainContent = styled.main`
  flex: 1;
  background-color: #f0f0f0;
`;

const PageContainer = styled.div`
  margin: 0 auto;
  background-color: #ffffff;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    width:70%;
    display:flex;
    align-items:center;
    justify-content:center;
  }
`;

const WorksSection = styled.div`
  padding: 40px 0;
`;

const WorksTitle = styled.h3`
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;

  span {
    color: #8cc63f;
    font-size: 40px;
  }
`;

const WorksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 40px 20px;
  justify-items:center;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  & > div {
    animation: ${fadeIn} 0.5s ease-in-out both;
    animation-delay: calc(var(--index) * 0.1s);
  }
`;

const WorkCard = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  width:fit-content;
`;

const WorkImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 70%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;

const WorkOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  font-size: 14px;
  text-align: center;
`;

const ViewMoreButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin: 20px auto;
  display: block;
  border-radius:10px;

  &:hover {
    background-color: #45a049;
  }
`;

const AnimatedTitle = animated(Title);
const AnimatedImage = animated(Image);
const AnimatedWorkCard = animated(WorkCard);

const ClientsAndWorksPage = () => {
  
    const [visibleWorks, setVisibleWorks] = useState(6);

  const handleViewMore = () => {
    if (visibleWorks + 3 >= works.length) {
      setVisibleWorks(works.length);
    } else {
      setVisibleWorks(prevVisible => prevVisible + 6);
    }
  };

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const titleAnimation = useSpring({
    from: { transform: 'translateY(-50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
  });

  const imageAnimation = useSpring({
    from: { transform: 'translateX(50px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
    delay: 300,
  });

  const worksTrail = useTrail(visibleWorks, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { mass: 5, tension: 2000, friction: 200 },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWrapper>
      <Navbar />
      <BannerContainer>
        <TextContainer>
          <AnimatedTitle style={titleAnimation}>Discover Our Clients<br></br> And<br></br> Work Credentials</AnimatedTitle>
        </TextContainer>
        <ImageContainer>
          <AnimatedImage src={bulb} alt="Banner" style={imageAnimation} />
        </ImageContainer>
      </BannerContainer>
      <MainContent>
        <PageContainer>
          <WorksSection>
            <WorksTitle>Our <span>Client's</span></WorksTitle>
            <WorksGrid>
              {worksTrail.map((animation, index) => (
                <AnimatedWorkCard style={animation} key={works[index].id}>
                  <WorkImage>
                    <img src={works[index].image} alt={works[index].description} />
                  </WorkImage>
                  <WorkOverlay>{works[index].description}</WorkOverlay>
                </AnimatedWorkCard>
              ))}
            </WorksGrid>
            <ViewMoreButton onClick={handleViewMore}>
              {visibleWorks < works.length ? "View More" : "And Many More"}
            </ViewMoreButton>
          </WorksSection>
        </PageContainer>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default ClientsAndWorksPage;