import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import emailjs from 'emailjs-com';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import rect4 from './../assets/rect4.png';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';

const colors = {
  background: '#ffffff',
  primaryText: '#004d00',
  secondary: '#009900',
  menuBackground: '#f1f8f1',
  green: '#4CAF50',
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideInFromLeft = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
`;

const slideInFromRight = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
`;

const PageContainer = styled.div`
  background-color: ${colors.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 1s ease-out;
`;

const Banner = styled.div`
  background-color: ${colors.green};
  color: white;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${fadeIn} 1.5s ease-out;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    margin-top:8%;
  }
`;

const BannerText = styled.div`
  flex: 1;
  animation: ${slideInFromLeft} 1s ease-out;

  @media (max-width: 768px) {
    margin-top: 10%;
  }
`;

const BannerTitle = styled.h1`
  font-size: 4em;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const BannerSubtitle = styled.p`
  font-size: 1em;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const BannerImage = styled.img`
  max-width: 41%;
  z-index: 1;
  margin-top: 4%;
  height: auto;
  margin-bottom: -100px;
  border-radius: 25px;
  animation: ${slideInFromRight} 1s ease-out;

  @media (max-width: 768px) {
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: -50px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin-top: 5%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1.5s ease-out;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    margin-top: 10%;
    padding: 15px;
  }
`;

const Title = styled.h2`
  color: ${colors.primaryText};
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  &:focus {
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  transition: all 0.3s ease-in-out;

  &:focus {
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  
  &.reset {
    background-color: #f0f0f0;
    color: ${colors.primaryText};
  }
  
  &.submit {
    background-color: ${colors.green};
    color: white;
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9em;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
  background: #F1F1F1;
  padding: 20px;
  animation: ${fadeIn} 2s ease-out;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactItem = styled.div`
  text-align: center;
  margin: 20px;
  transition: transform 0.3s ease-in-out;
  
  p {
    padding: 0;
    margin: 0;
  }

  &:hover {
    transform: scale(1.1);
    animation: ${bounce} 2s infinite;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const ContactIcon = styled.div`
  font-size: 2em;
  color: ${colors.secondary};
  margin-bottom: 10px;
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    company: '',
    contact: '',
    phone: '',
    email: '',
    address: '',
    requirements: '',
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
      'service_nyn6f6d',
      'template_v38d7xx',
      formData,
      'ljGWQg4ELzx-Qhfhd'
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }).catch((error) => {
      console.log('FAILED...', error);
    });
  };

  const handleReset = () => {
    setFormData({
      company: '',
      contact: '',
      phone: '',
      email: '',
      address: '',
      requirements: '',
    });
  };

  const whatsappNumber = '7507184478';
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  const emailAddress = 'arskcal@gmail.com';
  const emailLink = `mailto:${emailAddress}`;
  const phoneNumber1 = '7276158223';
  const phoneNumber2 = '7507184478';
  const phoneLink1 = `tel:${phoneNumber1}`;
  const phoneLink2 = `tel:${phoneNumber2}`;
  const mapUrl = 'https://maps.app.goo.gl/smM2Pf1Az1XhXYFk9';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <Navbar />
      <Banner>
        <BannerText>
          <BannerTitle>Contact us</BannerTitle>
          <BannerSubtitle>Delivering energy efficiency insights , sustainable impact</BannerSubtitle>
        </BannerText>
        <BannerImage src={rect4} alt="Solar Panels" />
      </Banner>
      <ContentContainer>
        <FormContainer>
          <Title>Get in Touch</Title>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="company"
              placeholder="Name of Company *"
              value={formData.company}
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="contact"
              placeholder="Name of Contact Person *"
              value={formData.contact}
              onChange={handleChange}
              required
            />
            <Input
              type="tel"
              name="phone"
              placeholder="Tel. No. / Cell No. *"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Email *"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="address"
              placeholder="Address / City / Location *"
              value={formData.address}
              onChange={handleChange}
              required
            />
            <TextArea
              name="requirements"
              placeholder="Requirements Details *"
              value={formData.requirements}
              onChange={handleChange}
              required
            />
            <ButtonContainer>
              <Button type="button" className="reset" onClick={handleReset}>
                Reset
              </Button>
              <Button type="submit" className="submit">
                Submit
              </Button>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </ContentContainer>
      <ContactInfo>
        <ContactItem>
          <ContactIcon><FaPhone /></ContactIcon>
          <h3>CALL US</h3>
          <ContactLink href={phoneLink1}>
            <p>{phoneNumber1}</p>
          </ContactLink>
          <ContactLink href={phoneLink2}>
            <p>{phoneNumber2}</p>
          </ContactLink>
        </ContactItem>
        <ContactItem>
          <ContactLink href={emailLink}>
            <ContactIcon><FaEnvelope /></ContactIcon>
            <h3>EMAIL US</h3>
            <p>{emailAddress}</p>
          </ContactLink>
        </ContactItem>
        <ContactItem>
          <ContactLink href={mapUrl} target="_blank" rel="noopener noreferrer">
            <ContactIcon><FaMapMarkerAlt /></ContactIcon>
            <h3>OUR LOCATION</h3>
            <p> Virar, Mumbai</p>
          </ContactLink>
        </ContactItem>
        <ContactItem>
          <ContactLink href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <ContactIcon><FaWhatsapp /></ContactIcon>
            <h3>WHATSAPP</h3>
            <p>{whatsappNumber}</p>
          </ContactLink>
        </ContactItem>
      </ContactInfo>
      <Footer />
      {showPopup && (
        <PopupOverlay>
          <PopupContent>
            <h3>Your response has been sent. Thank you!</h3>
          </PopupContent>
        </PopupOverlay>
      )}
    </PageContainer>
  );
};

export default EnquiryForm;