import React, { useState, useEffect } from 'react';
import { useSpring, animated, useTrail } from 'react-spring';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import powerplant2 from './../assets/powerplant2.jpg';
import homep from './../assets/homep.webp';
import h1 from './../assets/h1.png';
import h2 from './../assets/h2.png';
import h3 from './../assets/h3.png';
import faqsPdf from './../assets/ARS.pdf';
import Homecomponent from './Homecomponent';
import Homecontent2 from './Homecontent2';
import Vision from './Vision';
import ab1 from './../assets/ab1.webp';
import ab2 from './../assets/ab2.jpg';
import ab3 from './../assets/ab3.jpg';
import ab4 from './../assets/ab4.jpeg';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 70%;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 85%;
    padding: 0 10px;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  background-color: #4CAF50;
  color: white;
  padding: 40px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 1024px) {
    padding-right: 0;
    padding-bottom: 20px;
    margin-top: 1%;
  }
  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 20px;
  }
  h3{
    margin-top: 0%;
  }
  h1{
    margin-bottom: 0%;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 15px;
  font-weight: bold;
 
  span {
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-top: 15%;
  }
`;

const Button = styled.button`
  background-color: white;
  color: #4CAF50;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Image = styled.img`
  width: 70%;
  height: auto;
  margin-bottom: -70px;   
  border-radius: 25px; 
  margin-top: 8%;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 15%;
     margin-bottom: -70px;  
  }

  @media (max-width: 768px) {
    margin-bottom: -60px;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-bottom: -60px;
    margin-top: 15px;
  }
`;

const Section = styled.section`
  padding: 50px 0;

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  @media (max-width: 480px) {
    padding: 20px 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 5px;
  text-align: center;

  span {
    color: green;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutContent = styled.div`
  flex: 1;
  padding-right: 50px;
  border-radius: 15px;
  p{
      font-size: 20px;
      margin-top: 0%;
      padding-top: 0%;
    }
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    height: 250px;
  
  @media (max-width: 1024px) {
    padding-right: 0;
    margin-bottom: 20px;
    width: 100%;
  
  }
 
  }`;
const AboutImageSlider = styled.div`
  flex: 1;
  overflow: hidden;
  height: 300px;
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  
  @media (max-width: 1024px) {
    width: 90%;
    max-width: 100%;
    margin-top: 20px;
    height: 250px;
  }

  @media (max-width: 768px) {
    height: 200px;
  }

  @media (max-width: 480px) {
    height: 150px;
  }
`;

const SliderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: ${props => props.active ? 'block' : 'none'};
  transition: opacity 0.5s ease-in-out;
`;

const SolutionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SolutionCard = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin: 0 10px;
  
  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const SolutionIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
`;

const SolutionTitle = styled.h3`
  font-size: 1.2rem;
  color: #1a1a1a;
  margin-bottom: 10px;
`;

const SolutionText = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
`;

const ContactSection = styled.section`
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 60px 20px;
  max-width: 100%;

  h2 {
    font-size: 28px;
    margin: 0;
  }

  p {
    margin: 10px 0 20px;
  }
`;

const ContactButton = styled(motion.a)`
  display: inline-block;
  padding: 15px 30px;
  font-size: 1em;
  color: #4CAF50;
  background-color: white;
  border-radius: 25px;
  text-decoration: none;
  cursor: pointer;
`;

const StatsSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-top: 2%;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12%;
    flex-wrap: wrap;
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    gap: 5%;
  }

  @media (max-width: 480px) {
    gap: 2%;
  }
`;

const StatCard = styled.div`
  background: #1a1a2e;
  color: white;
  padding: 20px;
  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100px;
  min-width: 150px;
  max-width: 100px;
  border-radius: 15px;

  h3 {
    font-size: 28px;
    margin: 0;
  }

  p {
    margin: 10px 0 0;
  }
`;

const DownloadButton = styled(Button)`
  margin-bottom: 2%;
`;

const AnimatedTitle = animated(Title);
const AnimatedButton = animated(Button);
const AnimatedImage = animated(Image);
const AnimatedSolutionCard = animated(SolutionCard);
const AnimatedStatCard = animated(StatCard);

const HomePage = () => {
  const [hovered, setHovered] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [ab1, ab2, ab3, ab4];

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const titleAnimation = useSpring({
    from: { transform: 'translateY(-50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
  });

  const buttonAnimation = useSpring({
    from: { transform: 'scale(0.9)', opacity: 0 },
    to: { transform: 'scale(1)', opacity: 1 },
    config: { tension: 300, friction: 10 },
    delay: 500,
  });

  const imageAnimation = useSpring({
    from: { transform: 'translateX(50px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
    config: { tension: 300, friction: 10 },
    delay: 300,
  });

  const statAnimation = useSpring({
    number: 100,
    from: { number: 0 },
    config: { duration: 2000 },
  });

  const solutions = [
    { icon: h1, title: "Mandatory & Investment Grade Energy Audits", text: "Comprehensive energy audits, including turnkey and performance-based solutions, to optimize energy efficiency." },
    { icon: h2, title: "M&V Audits & Check Verification Audits (BEE, Govt. of India)", text: "Mandatory measurement and verification audits, ensuring compliance with BEE standards." },
    { icon: h3, title: "ECBC, Green Building, Carbon Footprinting Consultancy", text: "Specialized consultancy for energy conservation building codes, green building certifications, and carbon footprint analysis." }
  ];

  const trail = useTrail(solutions.length, {
    from: { opacity: 0, transform: 'translate3d(0, 40px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  });

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = faqsPdf;
    link.download = 'ARS_Energy_Auditors_FAQs.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <Navbar />
      <animated.div style={fadeIn}>
        <BannerContainer>
          <TextContainer>
            <AnimatedTitle style={titleAnimation}>
              Your <span>Partner </span> For <span> Sustainable </span> Future.
            </AnimatedTitle>
            <h1>ऊर्जा सक्षमता साधनं दीर्घजीविताय। </h1>
            <h3>Proper energy usage  leads to long life of Earth</h3>
            <AnimatedButton style={buttonAnimation} onClick={() => window.location.href = 'tel:7276158223'}>
              Schedule a call →
            </AnimatedButton>
          </TextContainer>
          <ImageContainer>
            <AnimatedImage style={imageAnimation} src={homep} alt="Solar Panels" />
          </ImageContainer>
        </BannerContainer>
      </animated.div>
      <Container>
        <Section>
          <SectionTitle>About Us</SectionTitle>
          <FlexContainer>
            <AboutContent>
              <p>
                Welcome to ARS Energy Auditors, an empanelled accredited energy auditor firm with the Bureau of Energy Efficiency (BEE), Government of India (EmAEA-60). We are a leading name in energy conservation, offering comprehensive energy auditing and consulting services. With a track record of over 1000 + audits across various sectors in India, Australia , China, Malaysia,  , Bahrain , UAE ,United States of America and Thailand, we are committed to helping organizations achieve energy efficiency and sustainability. Recognized with the Best Energy Auditor Award by the Maharashtra Energy Development Agency (MEDA) for two consecutive years (2017-18, 2018-19), we pride ourselves on delivering high-quality services.
              </p>
      {    /*    <DownloadButton onClick={handleDownload}>Download our PDF →</DownloadButton>*/}
            </AboutContent>
            <AboutImageSlider>
              {images.map((img, index) => (
                <SliderImage 
                  key={index} 
                  src={img} 
                  alt={`Slide ${index + 1}`} 
                  active={index === currentImageIndex}
                />
              ))}
            </AboutImageSlider>
          </FlexContainer>
        </Section>
        <Vision />
        <Section>
          <FlexContainer>
            <div>
              <SectionTitle>
                Our Solutions <br />
                <span>tailored for you</span>
              </SectionTitle>
              <SolutionsContainer>
                {trail.map((props, index) => (
                  <AnimatedSolutionCard
                    key={index}
                    style={{
                      ...props,
                      transform: hovered === index ? 'scale(1.05)' : 'scale(1)',
                      boxShadow: hovered === index ? '0 5px 15px rgba(0,0,0,0.3)' : '0 2px 5px rgba(0,0,0,0.1)',
                    }}
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    <SolutionIcon src={solutions[index].icon} alt={`Icon ${index + 1}`} />
                    <SolutionTitle>{solutions[index].title}</SolutionTitle>
                    <SolutionText>{solutions[index].text}</SolutionText>
                  </AnimatedSolutionCard>
                ))}
              </SolutionsContainer>
            </div>
          </FlexContainer>
          <StatsSection>
            {[
              { value: 1012, label: "ENERGY  AUDITS / PAT AUDITS" },
              { value: 256, label: "WATER AUDITS" },
              { value: 450, label: "TRAININGS & SEMINARS" },
              { value: 28, label: "OVERSEAS ASSIGNMENTS" },
              { value: 433, label: "ELECTRICAL SAFETY AUDITS" },
              { value: 39, label: "PLANT AND M/C VALUATIONS" },
              { value: 145, label: "CARBON FOOTPRINTING & ASSESMENT" },

              { value: 416374560 , label: "TOTAL ELECTRICITY SAVED IN kWh" },
              { value: 6476796, label: "COAL/SOLID FUEL SAVED IN TONS" },
              { value: 7813342, label: "AMOUNT OF GAS SAVED IN SCM  " },
              { value: 3250, label: "WATER SAVING IN MLD" },
              { value: 437, label: "MW OF SOLAR PROJECTS DPR" }
            ].map((stat, index) => (
              <AnimatedStatCard key={index}>
                <animated.h3>
                  {statAnimation.number.interpolate((val) => Math.floor(val * (stat.value / 100)))}
                </animated.h3>
                <p>{stat.label}</p>
              </AnimatedStatCard>
            ))}
          </StatsSection>
        </Section>
      </Container>
      <Homecomponent />
      <Homecontent2 />
      <ContactSection>
        <h2>Got project? Let's Connect!</h2>
        <p>Maximize your savings with our precise energy audits and sustainable energy strategies</p>
        <ContactButton href="enquiry">Get in touch</ContactButton>
      </ContactSection>
      <Footer />
    </>
  );
};

export default HomePage;
