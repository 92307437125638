import React, { useState } from 'react';
import MV1 from './../assets/MV1.jpg';
import styled from 'styled-components';
import Modal from 'react-modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const VisionSpan = styled.span`
  color: #333;
`;

const MissionSpan = styled.span`
  color: #4CAF50;
`;

const ImageSection = styled.div`
  flex: 1;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ContentSection = styled.div`
  flex: 1;
`;

const SectionTitle = styled.h2`
  color: ${props => props.color};
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 2px solid ${props => props.color};
  padding-bottom: 5px;
`;

const VisionText = styled.p`
  margin-bottom: 20px;
  line-height: 1.6;
`;

const MissionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const MissionItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const Checkmark = styled.span`
  color: #4CAF50;
  margin-right: 10px;
  font-size: 1.2rem;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const VisionMissionComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Container>
      <ImageSection>
        <Title>
          <VisionSpan>Vision</VisionSpan> & <MissionSpan>Mission</MissionSpan>
        </Title>
        <Image src={MV1} alt="Solar panels on grass" onClick={openModal} />
      </ImageSection>
      <ContentSection>
        <SectionTitle color="#333">VISION</SectionTitle>
        <VisionText>
          To emerge as a trustful partner in energy auditing and monitoring services
          supported by professional and technical team
        </VisionText>
        <SectionTitle color="#4CAF50">MISSION</SectionTitle>
        <MissionList>
          <MissionItem>
            <Checkmark>✓</Checkmark>
            To facilitate the process of energy auditing across various energy intensive
            sectors.
          </MissionItem>
          <MissionItem>
            <Checkmark>✓</Checkmark>
            To provide best-in-class energy monitoring services for effective and
            sustainable utilisation of resources.
          </MissionItem>
          <MissionItem>
            <Checkmark>✓</Checkmark>
            To maintain a healthy customer base through accountability in process
            leading to continuous improvement of organisation.
          </MissionItem>
        </MissionList>
      </ContentSection>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            maxWidth: '90%',
            borderRadius: '8px',
          },
        }}
        contentLabel="Example Modal"
      >
        <ModalImage src={MV1} alt="Solar panels on grass" />
      </Modal>
    </Container>
  );
};

export default VisionMissionComponent;
